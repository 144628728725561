import { FETCH_CARDS_DATA_FAILURE, FETCH_CARDS_DATA_REQUEST, FETCH_CARDS_DATA_SUCCESS } from "./actionCreator";

const initialState = {
  loading: false,
  error: null,
  cards: []
};

const cardsReducers = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CARDS_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_CARDS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        cards: action.payload,
        error: null
      };
    case FETCH_CARDS_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default cardsReducers;
