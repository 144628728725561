/*eslint-disable*/
import { Col, Row } from 'antd';
import React from 'react';
import { Aside, Content } from './overview/style';

const AuthLayout = (WraperContent) => {
  return function () {
    return (
      <Row>
        <Col xxl={8} xl={9} lg={12} md={8} xs={24} >
          <Aside>
            <div
              className="auth-side-content"
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
            >
              <img src={require('../../../static/img/auth/topShape.png')} alt="" className="topShape" />
              <img src={require('../../../static/img/auth/bottomShape.png')} alt="" className="bottomShape" />
              <Content>
                {/* <img style={{ width: '150px' }} src={require('../../../static/img/Logo_Dark.svg')} alt="" />
                <br />
                <br /> */}
                <div style={{textAlign:'center', display:'flex', justifyContent:'center', width:'100%', marginBottom:'20px'}}>
                  <img
                    className="auth-content-figure text-center"
                    style={{ width: '100px', borderRadius: '80px' }}
                    src={require('../../../static/img/auth/ACI.png')}
                    alt=""
                  />
                </div>
                <img
                  className="auth-content-figure"
                  style={{ width: '400px', borderRadius: '80px' }}
                  src={require('../../../static/img/auth/indexBanner.png')}
                  alt=""
                />
              </Content>
            </div>
          </Aside>
        </Col>

        <Col xxl={16} xl={15} lg={12} md={16} xs={24}>
          <WraperContent />
        </Col>
      </Row>
    );
  };
};

export default AuthLayout;
