/*eslint-disable*/
import { Spin } from 'antd';
import React, { Suspense, lazy } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import withAdminLayout from '../../layout/withAdminLayout';
import Dashboard from './dashboard';

const Fellowship = lazy(() => import('./fellowships'));
const Journals = lazy(() => import('./journals'));
const Events = lazy(() => import('./events'));
const Courses = lazy(() => import('./courses'));
const Congress = lazy(() => import('./congress'));
const Faculties = lazy(() => import('./faculty'));
const Publisher = lazy(() => import('./publisher'));
const Slider = lazy(() => import('./slider'));
const Doctors = lazy(() => import('./doctors'));
const Appointment = lazy(() => import('./appointment'));
const PhotoGallery = lazy(() => import('./photoGallery'));
const Wings = lazy(() => import('./wings'));
const About = lazy(() => import('./about'));

const Admin = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route path={path} component={Dashboard} />
        <Route path={`${path}/fellowships`} component={Fellowship} />
        <Route path={`${path}/courses`} component={Courses} />
        <Route path={`${path}/events`} component={Events} />
        <Route path={`${path}/congress`} component={Congress} />
        <Route path={`${path}/journals`} component={Journals} />
        <Route path={`${path}/publisher`} component={Publisher} />
        <Route path={`${path}/faculties`} component={Faculties} />
        <Route path={`${path}/slider`} component={Slider} />
        <Route path={`${path}/doctors`} component={Doctors} />
        <Route path={`${path}/appointments`} component={Appointment} />
        <Route path={`${path}/gallery`} component={PhotoGallery} />
        <Route path={`${path}/wings`} component={Wings} />
        <Route path={`${path}/about`} component={About} />
      </Suspense>
    </Switch>
  );
}

export default withAdminLayout(Admin);
