import { Menu } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import React from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';

const MenuItems = ({ darkMode, toggleCollapsed, topMenu }) => {
  const { path } = useRouteMatch();
  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');
  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
    >
      {/* <Menu.Item key="home">
        <NavLink onClick={toggleCollapsed} to={`${path}`}>
          Dashboard
        </NavLink>
      </Menu.Item> */}

      <SubMenu
        key="doctor"
        icon={!topMenu && <FeatherIcon icon="user" />}
        title={<span style={{ marginLeft: '20px' }}>Doctor</span>}
      >
        <Menu.Item key="all-admin">
          <NavLink onClick={toggleCollapsed} to={`${path}/doctors/admin-list/`}>
            Admin List
          </NavLink>
        </Menu.Item>
        <Menu.Item key="all-doctors">
          <NavLink onClick={toggleCollapsed} to={`${path}/doctors/doctor-list/`}>
            Doctors List
          </NavLink>
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key="fellowship"
        icon={!topMenu && <FeatherIcon icon="aperture" />}
        title={<span style={{ marginLeft: '20px' }}>Fellowship</span>}
      >
        <Menu.Item key="all-fellowship">
          <NavLink onClick={toggleCollapsed} to={`${path}/fellowships/`}>
            Fellowships
          </NavLink>
        </Menu.Item>
        {/* <Menu.Item key="post-new">
          <NavLink onClick={toggleCollapsed} to={`${path}/fellowships/posts`}>
            Post New
          </NavLink>
        </Menu.Item> */}
      </SubMenu>
      <SubMenu
        key="courses"
        icon={!topMenu && <FeatherIcon icon="file-text" />}
        title={<span style={{ marginLeft: '20px' }}>Courses</span>}
      >
        <Menu.Item key="all-courses">
          <NavLink onClick={toggleCollapsed} to={`${path}/courses/`}>
            Courses
          </NavLink>
        </Menu.Item>
        <Menu.Item key="post-course">
          <NavLink onClick={toggleCollapsed} to={`${path}/courses/posts`}>
            Post New
          </NavLink>
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key="journals"
        icon={!topMenu && <FeatherIcon icon="book" />}
        title={<span style={{ marginLeft: '20px' }}>Publisher</span>}
      >
        <Menu.Item key="all-journals">
          <NavLink onClick={toggleCollapsed} to={`${path}/publisher/`}>
            Journals
          </NavLink>
        </Menu.Item>
        <Menu.Item key="post-journals">
          <NavLink onClick={toggleCollapsed} to={`${path}/publisher/add-journal/`}>
            Post New
          </NavLink>
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key="events"
        icon={!topMenu && <FeatherIcon icon="calendar" />}
        title={<span style={{ marginLeft: '20px' }}>Events</span>}
      >
        <Menu.Item key="all-events">
          <NavLink onClick={toggleCollapsed} to={`${path}/events/`}>
            Events
          </NavLink>
        </Menu.Item>
        <Menu.Item key="post-event">
          <NavLink onClick={toggleCollapsed} to={`${path}/events/posts`}>
            Post New
          </NavLink>
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key="congress"
        icon={!topMenu && <FeatherIcon icon="file-text" />}
        title={<span style={{ marginLeft: '20px' }}>Congress</span>}
      >
        <Menu.Item key="all-congress">
          <NavLink onClick={toggleCollapsed} to={`${path}/congress/`}>
            Congress
          </NavLink>
        </Menu.Item>
        <Menu.Item key="post-congress">
          <NavLink onClick={toggleCollapsed} to={`${path}/congress/posts`}>
            Post New
          </NavLink>
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key="faculties"
        icon={!topMenu && <FeatherIcon icon="users" />}
        title={<span style={{ marginLeft: '20px' }}>Faculties</span>}
      >
        <Menu.Item key="list">
          <NavLink onClick={toggleCollapsed} to={`${path}/faculties`}>
            Faculties List
          </NavLink>
        </Menu.Item>
        <Menu.Item key="create">
          <NavLink onClick={toggleCollapsed} to={`${path}/faculties/add-faculty`}>
            Add Faculties
          </NavLink>
        </Menu.Item>
        
      </SubMenu>

      <SubMenu
        key="appointments"
        icon={!topMenu && <FeatherIcon icon="file" />}
        title={<span style={{ marginLeft: '20px' }}>Appointments</span>}
      >
        <Menu.Item key="doctors">
          <NavLink onClick={toggleCollapsed} to={`${path}/doctors`}>
            Doctors List
          </NavLink>
        </Menu.Item>
        <Menu.Item key="add-doctors">
          <NavLink onClick={toggleCollapsed} to={`${path}/doctors/add-doctor/`}>
            Add New Doctor
          </NavLink>
        </Menu.Item>
        <Menu.Item key="appointment">
          <NavLink onClick={toggleCollapsed} to={`${path}/appointments`}>
            Appointment List
          </NavLink>
        </Menu.Item>
        
      </SubMenu>
      <SubMenu
        key="wings"
        icon={!topMenu && <FeatherIcon icon="file" />}
        title={<span style={{ marginLeft: '20px' }}>Wings</span>}
      >
        <Menu.Item key="add-wing">
          <NavLink onClick={toggleCollapsed} to={`${path}/wings/add-new`}>
            Add Wings Item
          </NavLink>
        </Menu.Item>
        <Menu.Item key="appointment">
          <NavLink onClick={toggleCollapsed} to={`${path}/wings/list-item`}>
            Wing Item List
          </NavLink>
        </Menu.Item>
      </SubMenu>

      <SubMenu
        key="about"
        icon={!topMenu && <FeatherIcon icon="file" />}
        title={<span style={{ marginLeft: '20px' }}>About Page</span>}
      >
        <Menu.Item key="add-about">
          <NavLink onClick={toggleCollapsed} to={`${path}/about/add-new`}>
            Add Details
          </NavLink>
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key="sliders"
        icon={!topMenu && <FeatherIcon icon="file-text" />}
        title={<span style={{ marginLeft: '20px' }}>Sliders</span>}
      >
        <Menu.Item key="all-sliders">
          <NavLink onClick={toggleCollapsed} to={`${path}/slider/`}>
           All Sliders
          </NavLink>
        </Menu.Item>
        <Menu.Item key="post-slider">
          <NavLink onClick={toggleCollapsed} to={`${path}/slider/add-new`}>
            Add New Slider
          </NavLink>
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key="gallery"
        icon={!topMenu && <FeatherIcon icon="image" />}
        title={<span style={{ marginLeft: '20px' }}>Gallery</span>}
      >
        <Menu.Item key="gallery">
          <NavLink onClick={toggleCollapsed} to={`${path}/gallery`}>
            Images List
          </NavLink>
        </Menu.Item>
        <Menu.Item key="post-photo">
          <NavLink onClick={toggleCollapsed} to={`${path}/gallery/post-photo`}>
            Add New Image
          </NavLink>
        </Menu.Item>
        <Menu.Item key="add-link">
          <NavLink onClick={toggleCollapsed} to={`${path}/gallery/add-video-link`}>
            Add New Video
          </NavLink>
        </Menu.Item>
        <Menu.Item key="video-list">
          <NavLink onClick={toggleCollapsed} to={`${path}/gallery/video-list`}>
            Video List
          </NavLink>
        </Menu.Item>
      </SubMenu>
    </Menu>
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
