

const rootAPI = process.env.REACT_APP_API_ENDPOINT

const API = {
  auth: {
    login: `${rootAPI}/api/auth/login`,
    register: `${rootAPI}/api/auth/register`,
  },
  images: `${rootAPI}/src/uploads`,
  user: `${rootAPI}/api/auth/user`,
  users: `${rootAPI}/api/auth/users`,
  status: `${rootAPI}/api/auth`,
  congress: `${rootAPI}/api/congress`,
  courses: `${rootAPI}/api/courses`,
  journals: `${rootAPI}/api/journals`,
  events: `${rootAPI}/api/events`,
  fellowship: `${rootAPI}/api/fellowship`,
  faculty: `${rootAPI}/api/faculty`,
  slider: `${rootAPI}/api/slider`,
  doctor: `${rootAPI}/api/auth/doctor`,
  appointments: `${rootAPI}/api/appointments`,
  photos: `${rootAPI}/api/gallery/photos`,
  wings: `${rootAPI}/api/wings`,
  about: `${rootAPI}/api/about`,
  videos: `${rootAPI}/api/videos`,
};

export { API };

