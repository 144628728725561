import Cookies from 'js-cookie';
import actions from './actions';

const { loginBegin, loginSuccess, loginErr, logoutBegin, logoutSuccess, logoutErr } = actions;

const login = (token, user) => {
  return async (dispatch) => {
    try {
      dispatch(loginBegin());
      setTimeout(() => {
        Cookies.set('loggedIn', true);
        Cookies.set('token', token);
        Cookies.set('user', JSON.stringify(user));
        return dispatch(loginSuccess({ token, user }));
      }, 1000);
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};


const logOut = () => {
  return async (dispatch) => {
    try {
      dispatch(logoutBegin());
      Cookies.remove('loggedIn');
      Cookies.remove('token');
      Cookies.remove('user'); 
      dispatch(logoutSuccess(null));
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};


export { logOut, login };

