// actions/userActions.js
import axios from 'axios';
import { API } from '../../config/api';

export const FETCH_USER_DATA_REQUEST = 'FETCH_USER_DATA_REQUEST';
export const FETCH_USER_DATA_SUCCESS = 'FETCH_USER_DATA_SUCCESS';
export const FETCH_USER_DATA_FAILED = 'FETCH_USER_DATA_FAILED';
export const USER_DATA = "USER_DATA"

export const fetchUserDataRequest = () => ({
  type: FETCH_USER_DATA_REQUEST,
});

export const fetchUserDataSuccess = (data) => ({
  type: FETCH_USER_DATA_SUCCESS,
  payload: data,
});

export const fetchUserDataFailed = (error) => ({
  type: FETCH_USER_DATA_FAILED,
  payload: error,
});

export const userData = (data)=> ({
  type:USER_DATA,
  payload: data
 })

export const fetchUserData = () => {
  return async (dispatch) => {
    dispatch(fetchUserDataRequest());
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }

      const response = await axios.get(`${API.user}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log("Action creator",response)

      dispatch(fetchUserDataSuccess(response.data.user));
    } catch (error) {
      dispatch(fetchUserDataFailed(error.message || 'Failed to fetch user data'));
    }
  };
};
