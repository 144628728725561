// actionCreators.js

import { API } from '../../config/api/index';

// Action types for doctor data fetching
export const FETCH_CARDS_DATA_REQUEST = 'FETCH_CARDS_DATA_REQUEST';
export const FETCH_CARDS_DATA_SUCCESS = 'FETCH_CARDS_DATA_SUCCESS';
export const FETCH_CARDS_DATA_FAILURE = 'FETCH_CARDS_DATA_FAILURE';

// Action creators for doctor data fetching
export const fetchCardsDataRequest = () => ({
  type: FETCH_CARDS_DATA_REQUEST,
});

export const fetchCardsDataSuccess = (data) => ({
  type: FETCH_CARDS_DATA_SUCCESS,
  payload: data,
});

export const fetchCardsDataFailure = (error) => ({
  type: FETCH_CARDS_DATA_FAILURE,
  payload: error,
});

// Action creator for fetching doctor data
export const fetchCardsData = () => {
  return async (dispatch) => {
    dispatch(fetchCardsDataRequest());
    try {
      const response = await fetch(API.cards);
      const data = await response.json();
      dispatch(fetchCardsDataSuccess(data.cards));
    } catch (error) {
      dispatch(fetchCardsDataFailure(error.message));
    }
  };
};
